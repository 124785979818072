/*explain：;@author: cy; @version: 1.0; date: 2022/4/19;*/
/**
 * 画布中绘制矩形
 * 参数: cav-画布对象  list-矩形数组 i-选中矩形下标 画布宽高
 **/
let lineColors = '';
let maxCounts = 1;
let code = '';
/* 操作执行方法分发 */
export function draw(cav, list, i, lineColor = 'red', maxCount, codeType) {
  // 画布初始化
  let ctx = cav.getContext('2d');
  ctx.strokeStyle = lineColor;
  maxCounts = maxCount;
  lineColors = lineColor;
  ctx.lineWidth = 2;
  code = codeType;
  // 变量初始化
  let sX = 0; // 鼠标X坐标
  let sY = 0; // 鼠标Y坐标

  /*
   *鼠标移动进行第一层判断, 区分情况: 无矩形, 已有矩形无选中, 已有选中矩形
   */
  cav.onmousemove = function (em) {
    sX = em.offsetX;
    sY = em.offsetY;
    let iem = undefined; // 鼠标移动时临时存储当前鼠标所在矩形的下标

    if (list.length === 0) {
      // **** 无矩形 ****
      // 绘制新矩形
      if (code) {
        newDraw(cav, ctx, list);
      }
    } else if (i === undefined) {
      // **** 已有矩形无选中 ****
      // 判断鼠标位置
      list.forEach(function (value, index) {
        if (
          value.w > 0 &&
          value.h > 0 &&
          sX > value.x &&
          sX < value.x + value.w &&
          sY > value.y &&
          sY < value.y + value.h
        ) {
          // 鼠标在右下方向生成的矩形中
          iem = index;
          judgeDraw(cav, ctx, list, iem);
        }
        if (
          value.w < 0 &&
          value.h > 0 &&
          sX < value.x &&
          sX > value.x + value.w &&
          sY > value.y &&
          sY < value.y + value.h
        ) {
          // 鼠标在左下方向生成的矩形中
          iem = index;
          judgeDraw(cav, ctx, list, iem);
        }
        if (
          value.w > 0 &&
          value.h < 0 &&
          sX > value.x &&
          sX < value.x + value.w &&
          sY < value.y &&
          sY > value.y + value.h
        ) {
          // 鼠标在右上方向生成的矩形中
          iem = index;
          judgeDraw(cav, ctx, list, iem);
        }
        if (
          value.w < 0 &&
          value.h < 0 &&
          sX < value.x &&
          sX > value.x + value.w &&
          sY < value.y &&
          sY > value.y + value.h
        ) {
          // 鼠标在左上方向生成的矩形中
          iem = index;
          judgeDraw(cav, ctx, list, iem);
        }
        if (iem === undefined) {
          // 鼠标不在矩形中
          if (code) {
            newDraw(cav, ctx, list);
          }
        }
      });
    } else {
      // **** 已有选中矩形 ****
      // 判断鼠标位置
      for (let index = 0; index < list.length; index++) {
        let value = list[index];
        if (value) {
          if (
            sX < value.x + 5 &&
            sX > value.x - 5 &&
            sY < value.y + 5 &&
            sY > value.y - 5
          ) {
            // ***  鼠标在起点角  ***
            if (index === i) {
              changeDraw(cav, ctx, list, i, 1);
              break;
            }
          } else if (
            sX < value.x + value.w + 5 &&
            sX > value.x + value.w - 5 &&
            sY < value.y + 5 &&
            sY > value.y - 5
          ) {
            // ***  鼠标在起点横向角  ***
            if (index === i) {
              changeDraw(cav, ctx, list, i, 2);
              break;
            }
          } else if (
            sX < value.x + 5 &&
            sX > value.x - 5 &&
            sY < value.y + value.h + 5 &&
            sY > value.y + value.h - 5
          ) {
            // ***  鼠标在起点纵向角  ***
            if (index === i) {
              changeDraw(cav, ctx, list, i, 3);
              break;
            }
          } else if (
            sX < value.x + value.w + 5 &&
            sX > value.x + value.w - 5 &&
            sY < value.y + value.h + 5 &&
            sY > value.y + value.h - 5
          ) {
            // ***  鼠标在终点角  ***
            if (index === i) {
              changeDraw(cav, ctx, list, i, 4);
              break;
            }
          } else if (
            value.w > 0 &&
            value.h > 0 &&
            sX > value.x &&
            sX < value.x + value.w &&
            sY > value.y &&
            sY < value.y + value.h
          ) {
            // ***  鼠标在右下方向生成的矩形中  ***
            iem = index;
            judgeDraw(cav, ctx, list, index);
            break;
          } else if (
            value.w < 0 &&
            value.h > 0 &&
            sX < value.x &&
            sX > value.x + value.w &&
            sY > value.y &&
            sY < value.y + value.h
          ) {
            // ***  鼠标在左下方向生成的矩形中  ***
            iem = index;
            judgeDraw(cav, ctx, list, index);
            break;
          } else if (
            value.w > 0 &&
            value.h < 0 &&
            sX > value.x &&
            sX < value.x + value.w &&
            sY < value.y &&
            sY > value.y + value.h
          ) {
            // ***  鼠标在右上方向生成的矩形中  ***
            iem = index;
            judgeDraw(cav, ctx, list, index);
            break;
          } else if (
            value.w < 0 &&
            value.h < 0 &&
            sX < value.x &&
            sX > value.x + value.w &&
            sY < value.y &&
            sY > value.y + value.h
          ) {
            // ***  鼠标在左上方向生成的矩形中  ***
            iem = index;
            judgeDraw(cav, ctx, list, index);
            break;
          } else {
            if (iem === undefined) {
              // *** 鼠标不在矩形中 ***
              if (code) {
                newDraw(cav, ctx, list);
              }
            }
          }
        }
      }
    }

    /* 鼠标移出画布区域时保存选中矩形下标(如有) */
    cav.onmouseout = function () {
      if (i !== undefined) {
        // 初始化
        draw(cav, list, i, lineColors, maxCounts, code);
      }
    };
  };
}

/* 编辑矩形四个角 */
function changeDraw(cav, ctx, list, i, site) {
  cav.style.cursor = 'pointer';

  // site: 操作矩形角的位置, 1-起点 2-起点横向 3-起点纵向 4-终点
  let mark = list[i];

  /* 按下鼠标左键 */
  cav.onmousedown = function (ed) {

    // 保存鼠标落下位置的X, Y坐标, firefox中鼠标移动后ed.offsetX ed.offsetY会变成 0, 需要使用临时参数存储起来
    let sX = ed.offsetX; // 起点X坐标
    let sY = ed.offsetY; // 起点Y坐标

    /* 移动鼠标 */
    cav.onmousemove = function (em) {
      // 计算绘制数据
      let iframe = {};
      switch (site) {
        case 1:
          // eslint-disable-next-line no-debugger
          iframe = {
            x: em.offsetX,
            y: em.offsetY,
            w: mark.w - (em.offsetX - sX),
            h: mark.h - (em.offsetY - sY),
            lineColors: mark.lineColors,
            code: mark.code,
          };
          break;
        case 2:
          iframe = {
            x: mark.x,
            y: mark.y + (em.offsetY - sY),
            w: mark.w + (em.offsetX - sX),
            h: mark.h - (em.offsetY - sY),
            lineColors: mark.lineColors,
            code: mark.code,
          };
          break;
        case 3:
          iframe = {
            x: mark.x + (em.offsetX - sX),
            y: mark.y,
            w: mark.w - (em.offsetX - sX),
            h: mark.h + (em.offsetY - sY),
            lineColors: mark.lineColors,
            code: mark.code,
          };
          break;
        case 4:
          iframe = {
            x: mark.x,
            y: mark.y,
            w: mark.w + (em.offsetX - sX),
            h: mark.h + (em.offsetY - sY),
            lineColors: mark.lineColors,
            code: mark.code,
          };
          break;
      }
      list.splice(i, 1, iframe);

      // 重新绘制
      reDraw(cav, ctx, list, i);
    };

    /* 鼠标离开矩形区 */
    cav.onmouseout = function () {
      // 重新绘制
      reDraw(cav, ctx, list);
      // 初始化
      draw(cav, list, undefined, lineColors, maxCounts, code);
    };

    /* 监听键盘, 点击后可以控制删除, 由于移动矩形事件已经监听了onmousemove, 所以在移动矩形方法中仍有一次调用 */
    delDraw(cav, ctx, list, i);
  };
}

/* 绘制新矩形 */
function newDraw(cav, ctx, list) {
  // eslint-disable-next-line no-debugger
  cav.style.cursor = list.length < maxCounts ? 'crosshair' : 'default';

  // 初始化变量
  let start = false; // 画框状态, false时不执行画框操作
  let sX = 0; // 起点X坐标
  let sY = 0; // 起点Y坐标

  /* 按下鼠标左键 */
  cav.onmousedown = function (ed) {
    /* 使用变量 */
    start = true;
    sX = ed.offsetX;
    sY = ed.offsetY;
    /* 重置按键监听, 防止选中取消后仍可删除 */
    delDraw(cav, ctx, list, null);
    /* 鼠标移动 */
    if (list.length < maxCounts) {
      cav.onmousemove = function (em) {
        if (start) {
          // 重新绘制
          reDraw(cav, ctx, list);
          // 设置边框为虚线
          ctx.beginPath();
          ctx.setLineDash([8, 4]);
          ctx.rect(sX, sY, em.offsetX - sX, em.offsetY - sY);
          ctx.stroke();
        }
      };

      /* 鼠标抬起 */
      cav.onmouseup = function (eu) {
        if (
          start &&
          Math.abs(eu.offsetX - sX) > 10 &&
          Math.abs(eu.offsetY - sY) > 10
        ) {
          // 改变矩形数组
          let frame = {
            x: sX,
            y: sY,
            w: eu.offsetX - sX,
            h: eu.offsetY - sY,
            code,
            lineColors,
          };
          if (list.length >= maxCounts) {
            return;
          }
          list.push(frame);
          // 重新绘制
          reDraw(cav, ctx, list);
          // 改变画框状态
          start = false;
          // 初始化
          draw(cav, list, undefined, lineColors, maxCounts, code);
        } else {
          // 重新绘制
          reDraw(cav, ctx, list);
          // 改变画框状态
          start = false;
          // 初始化
          draw(cav, list, undefined, lineColors, maxCounts, code);
        }
      };

      /* 鼠标离开矩形区 */
      cav.onmouseout = function (eo) {
        if (
          start &&
          Math.abs(eo.offsetX - sX) > 10 &&
          Math.abs(eo.offsetY - sY) > 10
        ) {
          // 改变矩形数组
          let frame = {
            x: sX,
            y: sY,
            w: eo.offsetX - sX,
            h: eo.offsetY - sY,
            code,
            lineColors: lineColors,
          };
          list.push(frame);
          // 重新绘制
          reDraw(cav, ctx, list);
          // 改变画框状态
          start = false;
          // 初始化
          draw(cav, list, undefined, lineColors, maxCounts, code);
        } else {
          // 重新绘制
          reDraw(cav, ctx, list);
          // 改变画框状态
          start = false;
          // 初始化
          draw(cav, list, undefined, lineColors, maxCounts, code);
        }
      };
    }
  };
}

/* 选中矩形, 重绘矩形, 并分发后续事件 */
function judgeDraw(cav, ctx, list, iem) {
  cav.style.cursor = 'default';
  // 初始化变量
  let sX = 0; // 起点X坐标
  let sY = 0; // 起点Y坐标
  /* 按下鼠标左键 */
  cav.onmousedown = function (ed) {
    sX = ed.offsetX;
    sY = ed.offsetY;

    // 更改选中状态, 重绘矩形

    reDraw(cav, ctx, list, iem);

    /* 当仅点击选中矩形便抬起鼠标后, 重新初始化画布 */
    cav.onmouseup = function () {
      // 重绘矩形
      reDraw(cav, ctx, list, iem);
      // eslint-disable-next-line no-debugger
      // 初始化
      draw(cav, list, iem, lineColors, maxCounts, code);
    };

    /* 按住拖动鼠标, 移动选中矩形*/
    moveDraw(cav, ctx, list, iem, sX, sY);

    /* 监听键盘, 点击后可以控制删除, 由于移动矩形事件已经监听了onmousemove, 所以在移动矩形方法中仍有一次调用 */
    delDraw(cav, ctx, list, iem);
  };
}

/* 移动矩形 */
function moveDraw(cav, ctx, list, i, sX, sY) {
  let mark = list[i];
  cav.onmousemove = function (em) {
    let iframe = {
      x: mark.x + (em.offsetX - sX),
      y: mark.y + (em.offsetY - sY),
      w: mark.w,
      h: mark.h,
      lineColors: mark.lineColors,
      code: mark.code,
    };
    // if (iframe.x < 0) iframe.x = 0;
    // if (iframe.y < 0) iframe.y = 0;
    // if (iframe.h > canvasW) iframe.h = canvasW;
    // if (iframe.h > canvasH) iframe.h = canvasH;
    list.splice(i, 1, iframe);
    /* 监听键盘, 使矩形在移动后仍可删除, 在点击未移动过的矩形时仍有一次监听 */
    delDraw(cav, ctx, list, i);
    // 重新绘制
    reDraw(cav, ctx, list, i);
  };

  cav.onmouseup = function () {
    // 重绘矩形
    reDraw(cav, ctx, list, i);

    // 初始化
    draw(cav, list, i, lineColors, maxCounts, code);
  };
}

/* 删除矩形 */
function delDraw(cav, ctx, list, i) {
  /* 按键事件 */
  if (i === null) {
    // i为null时阻止按键监听事件冒泡
    document.onkeydown = function () {
      return false;
    };
  } else {
    // 监听按键事件
    document.onkeydown = function (k) {
      if (k.code === 'Delete' && i !== null) {
        if (list.length >= 1) {
          // 删除数组元素
          list.splice(i, 1);
          // 重绘矩形
          reDraw(cav, ctx, list);
        } else {
          /* 矩形数组长度为0, 已将矩形框全部删除 */ ctx.clearRect(
            0,
            0,
            cav.width,
            cav.height
          );
        }
        // 重置监听状态, 防止删除完毕后, 按键监听不消失
        delDraw(cav, ctx, list, null);
        // 重绘矩形
        reDraw(cav, ctx, list);
        // 初始化
        draw(cav, list, undefined, lineColors, maxCounts, code);
      }
    };
  }
}

/* 重绘所有矩形 */
function reDraw(cav, ctx, list, i) {
  ctx.setLineDash([8, 0]); // 设置边框为实线
  ctx.clearRect(0, 0, cav.width, cav.height);
  // 绘制未选中部分
  list.forEach(function (value, index) {
    if (i === undefined || index !== i) {
      ctx.beginPath();
      ctx.strokeStyle = value.lineColors || lineColors;
      ctx.rect(value.x, value.y, value.w, value.h);
      ctx.stroke();
    }
  });
  // 绘制已选中部分
  list.forEach(function (value, index) {
    if (index === i) {
      /* 绘制方框 */
      ctx.beginPath();
      ctx.strokeStyle = value.lineColors;
      ctx.rect(value.x, value.y, value.w, value.h);
      ctx.fillStyle = 'RGBA(102,102,102,0.2)';
      ctx.fillRect(value.x, value.y, value.w, value.h);
      ctx.stroke();
      // 绘制四个角的圆圈
      ctx.beginPath();
      ctx.strokeStyle = value.lineColors;
      ctx.arc(value.x, value.y, 4, 0, Math.PI * 2);
      ctx.fillStyle = value.lineColors;
      ctx.fill(); // 画起点实心圆
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(value.x, value.y + value.h, 4, 0, Math.PI * 2);
      ctx.fillStyle = value.lineColors;
      ctx.fill(); // 画起点纵向实心圆
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(value.x + value.w, value.y + value.h, 4, 0, Math.PI * 2);
      ctx.fillStyle = value.lineColors;
      ctx.fill(); // 画起点横向实心圆
      ctx.stroke();
      ctx.beginPath();
      ctx.arc(value.x + value.w, value.y, 4, 0, Math.PI * 2);
      ctx.fillStyle = value.lineColors;
      ctx.fill(); // 画终点实心圆
      ctx.stroke();
    }
  });
}
// export function drawLine(el, color = 'orange', list = []) {
//   // eslint-disable-next-line no-debugger
//   let config = new Proxy(
//     { color },
//     {
//       get: function (obj, prop) {
//         // 默认行为是返回属性值
//         console.log(obj, prop, 'get');
//         return obj[prop];
//       },
//       set: function (obj, prop, value) {
//         obj[prop] = value;
//         console.log(obj, prop, value, 'set');
//         // 表示成功
//         return true;
//       },
//     }
//   );
//   const ctx = el.getContext('2d');
//   let firstTime = 0;
//   let lastTime = 0;
//   let isClick = false;
//   let pointMap = new Map();
//   let currentIndex = -1;
//   let count = list.length - 1;
//   let isDown = false; // 是否按下
//   const downPoint = { x: 0, y: 0 };
//   let moveLineInitData = null;
//   // 起点
//   el.onmousedown = (e) => {
//     firstTime = Date.now();
//     isDown = true;
//     pointMap.clear();
//     if (e.type !== 'mousedown') {
//       return;
//     }
//     const x = e.pageX - Math.floor(el.getBoundingClientRect().left);
//     const y = e.pageY - Math.floor(el.getBoundingClientRect().top);
//     downPoint.x = x;
//     downPoint.y = y;
//     if (currentIndex !== -1) {
//       moveLineInitData = JSON.parse(JSON.stringify(list[currentIndex]));
//     }
//     const line = {
//       p0: { x, y },
//       // p1: { x, y },
//       color: config.color,
//     };
//     list.push(line);
//     count = list.length - 1;
//   };
//   // 移动
//   el.onmousemove = (e) => {
//     const x = e.pageX - Math.floor(el.getBoundingClientRect().left);
//     const y = e.pageY - Math.floor(el.getBoundingClientRect().top);
//     if (isDown) {
//       pointMap.set(x, y);
//       if (
//         currentIndex !== -1 &&
//         moveLineInitData &&
//         Reflect.has(moveLineInitData, 'p0') &&
//         Reflect.has(moveLineInitData, 'p1')
//       ) {
//         const line = list[currentIndex];
//         const movePoint = {
//           x: x - downPoint.x,
//           y: y - downPoint.y,
//         };
//         line.p0.x = moveLineInitData.p0.x + movePoint.x;
//         line.p0.y = moveLineInitData.p0.y + movePoint.y;
//         line.p1.x = moveLineInitData.p1.x + movePoint.x;
//         line.p1.y = moveLineInitData.p1.y + movePoint.y;
//         reDrawLine();
//       }
//     }
//   };
//   // 终点
//   el.onmouseup = (e) => {
//     const x = e.pageX - Math.floor(el.getBoundingClientRect().left);
//     const y = e.pageY - Math.floor(el.getBoundingClientRect().top);
//     lastTime = Date.now();
//     isClick = lastTime - firstTime < 200;
//     const line = list[count];
//     if (isDown && !isClick && line && currentIndex === -1) {
//       const p0 = line.p0;
//       if (!pointMap.size || (p0 && p0.x === x)) {
//         return;
//       }
//       line.p1 = { x, y };
//       ctx.beginPath();
//       ctx.moveTo(p0.x, p0.y);
//       ctx.strokeStyle = config.color;
//       ctx.lineTo(x, y);
//       ctx.lineWidth = 2;
//       ctx.stroke();
//     } else {
//       list.splice(count, 1);
//     }
//     list = list.filter((item) => item && item.p1);
//     isDown = false;
//     // list = list.filter((item) => item);
//     console.log(list, 'list');
//   };
//
//   el.onclick = (e) => {
//     if (isClick) {
//       const x = e.pageX - Math.floor(el.getBoundingClientRect().left);
//       const y = e.pageY - Math.floor(el.getBoundingClientRect().top);
//       if (currentIndex !== -1) {
//         reDrawLine();
//       }
//       getSelectedLineIndex(x, y);
//     }
//   };
//
//   const reDrawLine = () => {
//     ctx.clearRect(
//       0,
//       0,
//       el.getBoundingClientRect().width,
//       el.getBoundingClientRect().height
//     );
//     list.forEach((item, index) => {
//       const p0 = item.p0;
//       const p1 = item.p1;
//       if (!p1) {
//         return;
//       }
//       ctx.beginPath();
//       ctx.strokeStyle = item.color || config.color;
//       ctx.lineWidth = 2;
//       ctx.moveTo(p0.x, p0.y);
//       ctx.lineTo(p1.x, p1.y);
//       ctx.stroke();
//       if (currentIndex !== -1 && currentIndex === index) {
//         drawArc(item, item.p0, item.p1);
//       }
//     });
//   };
//   if (list.length) {
//     reDrawLine();
//   }
//   const getLineL = (p0, p1) => {
//     console.log(p0, p1, 'getLineL');
//     return Math.abs(
//       Math.sqrt(Math.pow(p1.x - p0.x, 2) + Math.pow(p1.y - p0.y, 2))
//     );
//   };
//   const getSelectedLineIndex = (x, y) => {
//     for (let i = 0; i < list.length; i++) {
//       const item = list[i];
//       console.log(item, 'item----');
//       if (!item) {
//         continue;
//       }
//       const p0 = item.p0;
//       const p1 = item.p1;
//
//       const p2 = { x, y };
//       const k = getLineL(p0, p1);
//       const k0 = getLineL(p2, p0);
//       const k1 = getLineL(p2, p1);
//
//       console.log(k, k0, k1, '----------');
//       const difference = k0 + k1 - k;
//       if (
//         difference <= 0.1 &&
//         Math.min(p0.x, p1.x) <= x &&
//         x <= Math.max(p0.x, p1.x) &&
//         Math.min(p0.y, p1.y) <= y &&
//         y <= Math.max(p0.y, p1.y)
//       ) {
//         console.log(p0, p1, '选中');
//         currentIndex = i;
//         drawArc(item, p0, p1);
//         break;
//       } else {
//         console.log(p1, p0, x, y);
//         currentIndex = -1;
//         reDrawLine();
//       }
//     }
//   };
//
//   function drawArc(line) {
//     const { p0, p1 } = line;
//     ctx.beginPath();
//     const R = 2 * Math.PI;
//     ctx.arc(p0.x, p0.y, 4, 0, R);
//     ctx.arc(p1.x, p1.y, 4, 0, R);
//     ctx.strokeStyle = 'transparent';
//     ctx.fillStyle = line.color || config.color;
//     ctx.fill();
//     ctx.stroke();
//   }
//   document.onkeydown = (e) => {
//     if (e.code === 'Delete') {
//       if (currentIndex !== -1) {
//         list.splice(currentIndex, 1);
//         reDraw();
//       }
//       currentIndex = -1;
//     }
//   };
//   return {
//     config: (options) => {
//       Object.assign(config, options);
//       console.log(config, '----config');
//     },
//   };
// }
export function drawLine(cav, list, i, lineColor = 'green') {
  // 画布初始化
  let ctx = cav.getContext('2d');
  // 变量初始化
  let sX = 0; // 鼠标X坐标
  let sY = 0; // 鼠标Y坐标
  ctx.strokeStyle = lineColor;
  ctx.lineWidth = 2;

  cav.onmousemove = function (em) {
    sX = em.offsetX;
    sY = em.offsetY;
    let iem = undefined; // 鼠标移动时临时存储当前鼠标所在矩形的下标
    if (list.length === 0) {
      newLine(cav, ctx, list);
    } else if (i === undefined) {
      // **** 已有矩形无选中 ****
      // 判断鼠标位置
      list.forEach(function (value, index) {
        if (value.x > 0 && value.ox > 0 && sX > value.x && sX < value.ox) {
          // 鼠标线段上
          iem = index;
          judgeDrawLine(cav, ctx, list, iem);
        }

        if (iem === undefined) {
          // 鼠标不在矩形中
          newLine(cav, ctx, list);
        }
      });
    } else {
      // 已有选中线段
      for (let index = 0; index < list.length; index++) {
        let p1 = {
          x: list[index].x,
          y: list[index].y,
        };
        let p2 = {
          x: list[index].ox,
          y: list[index].oy,
        };
        let value = list[index];
        if (value) {
          if (
            sX < value.x + 4 &&
            sX > value.x - 4 &&
            sY < value.y + 4 &&
            sY > value.y - 4
          ) {
            // ***  鼠标在起点 ***
            if (index === i) {
              changeDrawLine(cav, ctx, list, i, 1);
              break;
            }
          } else if (
            sX < value.ox + 4 &&
            sX > value.ox - 4 &&
            sY < value.oy + 4 &&
            sY > value.oy - 4
          ) {
            // ***  鼠标在终点  ***
            if (index === i) {
              changeDrawLine(cav, ctx, list, i, 4);
              break;
            }
          } else if (getLineL(p1.x, p1.y, p2.x, p2.y, sX, sY, 0.01)) {
            // ***  鼠标在两点之间  ***
            iem = index;
            judgeDrawLine(cav, ctx, list, index);
            break;
          } else {
            if (iem === undefined) {
              // *** 鼠标不在矩形中 ***
              newLine(cav, ctx, list);
            }
          }
        }
      }
    }
  };
}
function newLine(cav, ctx, list) {
  // 初始化变量
  let start = false; // 画框状态, false时不执行画框操作
  let sX = 0; // 起点X坐标
  let sY = 0; // 起点Y坐标
  cav.onmousedown = function (ed) {
    /* 使用变量 */
    start = true;
    sX = ed.offsetX;
    sY = ed.offsetY;
    /* 重置按键监听, 防止选中取消后仍可删除 */
    delLine(cav, ctx, list, null);
    /* 鼠标移动 */
    // if (list.length < maxCounts) {
    cav.onmousemove = function (em) {
      if (start) {
        // 重新绘制
        reDrawLine(cav, ctx, list);
        // 设置边框为虚线
        ctx.beginPath();
        ctx.setLineDash([8, 4]);
        ctx.strokeStyle = 'green';
        ctx.lineWidth = 2;
        ctx.moveTo(sX, sY);
        ctx.lineTo(em.offsetX, em.offsetY);
        ctx.stroke();
      }
    };

    /* 鼠标抬起 */
    cav.onmouseup = function (eu) {
      if (
        start &&
        Math.abs(eu.offsetX - sX) > 10 &&
        Math.abs(eu.offsetY - sY) > 10
      ) {
        // 改变矩形数组
        let frame = {
          x: sX,
          y: sY,
          ox: eu.offsetX,
          oy: eu.offsetY,
          color: lineColors,
        };
        // if (list.length >= maxCounts) {
        //   return;
        // }
        list.push(frame);
        // 重新绘制
        reDrawLine(cav, ctx, list);
        // 改变画框状态
        start = false;
        // 初始化
        drawLine(cav, list, undefined);
      } else {
        // 重新绘制
        reDrawLine(cav, ctx, list);
        // 改变画框状态
        start = false;
        // 初始化
        drawLine(cav, list, undefined);
      }
    };

    /* 鼠标离开矩形区 */
    cav.onmouseout = function (eo) {
      if (
        start &&
        Math.abs(eo.offsetX - sX) > 10 &&
        Math.abs(eo.offsetY - sY) > 10
      ) {
        // 改变矩形数组
        let frame = {
          x: sX,
          y: sY,
          ox: eo.offsetX,
          oy: eo.offsetY,
          color: lineColors,
        };
        list.push(frame);
        // 重新绘制
        reDrawLine(cav, ctx, list);
        // 改变画框状态
        start = false;
        // 初始化
        drawLine(cav, list, undefined);
      } else {
        // 重新绘制
        reDrawLine(cav, ctx, list);
        // 改变画框状态
        start = false;
        // 初始化
        drawLine(cav, list, undefined);
      }
    };
    // }
  };
}
function reDrawLine(cav, ctx, list, i) {
  ctx.setLineDash([8, 0]); // 设置边框为实线
  ctx.clearRect(0, 0, cav.width, cav.height);
  // 绘制未选中部分
  list.forEach(function (value, index) {
    if (i === undefined || index !== i) {
      ctx.beginPath();
      ctx.strokeStyle = value.color;
      ctx.moveTo(value.x, value.y);
      ctx.lineTo(value.ox, value.oy);
      ctx.stroke();
    }
  });
  // 绘制已选中部分
  list.forEach(function (value, index) {
    if (index === i) {
      /* 绘制方框 */
      ctx.beginPath();
      ctx.strokeStyle = value.color;
      ctx.moveTo(value.x, value.y);
      ctx.lineTo(value.ox, value.oy);
      ctx.stroke();
      ctx.beginPath();
      const R = 2 * Math.PI;
      ctx.arc(value.x, value.y, 4, 0, R);
      ctx.arc(value.ox, value.oy, 4, 0, R);
      ctx.strokeStyle = 'transparent';
      ctx.fillStyle = value.color;
      ctx.fill();
      ctx.stroke();
    }
  });
}
function judgeDrawLine(cav, ctx, list, iem) {
  cav.style.cursor = 'default';
  // 初始化变量
  let sX = 0; // 起点X坐标
  let sY = 0; // 起点Y坐标
  /* 按下鼠标左键 */
  cav.onmousedown = function (ed) {
    sX = ed.offsetX;
    sY = ed.offsetY;

    // 更改选中状态, 重绘线段

    reDrawLine(cav, ctx, list, iem);

    /* 当仅点击选中矩形便抬起鼠标后, 重新初始化画布 */
    cav.onmouseup = function () {
      // 重绘线段
      reDrawLine(cav, ctx, list, iem);

      // 初始化
      drawLine(cav, list, iem);
    };

    /* 按住拖动鼠标, 移动选中矩形*/
    moveDrawLine(cav, ctx, list, iem, sX, sY);

    /* 监听键盘, 点击后可以控制删除, 由于移动矩形事件已经监听了onmousemove, 所以在移动矩形方法中仍有一次调用 */
    delLine(cav, ctx, list, iem);
  };
}
function changeDrawLine(cav, ctx, list, i, site) {
  cav.style.cursor = 'pointer';

  // site: 操作矩形角的位置, 1-起点 4-终点
  let mark = list[i];

  /* 按下鼠标左键 */
  cav.onmousedown = function () {
    // 保存鼠标落下位置的X, Y坐标, firefox中鼠标移动后ed.offsetX ed.offsetY会变成 0, 需要使用临时参数存储起来
    // let sX = ed.offsetX; // 起点X坐标
    // let sY = ed.offsetY; // 起点Y坐标
    //
    /* 移动鼠标 */
    cav.onmousemove = function (em) {
      // 计算绘制数据
      let iframe = {};
      switch (site) {
        case 1:
          iframe = {
            x: mark.ox,
            y: mark.oy,
            ox: em.offsetX,
            oy: em.offsetY,
            color: mark.color,
          };
          break;
        case 4:
          iframe = {
            x: mark.x,
            y: mark.y,
            ox: em.offsetX,
            oy: em.offsetY,
            color: mark.color,
          };
          break;
      }
      list.splice(i, 1, iframe);
      // 重新绘制
      reDrawLine(cav, ctx, list, i);
    };

    /* 鼠标离开矩形区 */
    cav.onmouseout = function () {
      // 重新绘制
      reDrawLine(cav, ctx, list);
      // 初始化
      drawLine(cav, list, undefined);
    };

    /* 监听键盘, 点击后可以控制删除, 由于移动矩形事件已经监听了onmousemove, 所以在移动矩形方法中仍有一次调用 */
    delLine(cav, ctx, list, i);
  };
}
function moveDrawLine(cav, ctx, list, i, sX, sY) {
  let mark = list[i];
  console.log('moveDrawLine---', list);
  cav.onmousemove = function (em) {
    let iframe = {
      x: mark.x + (em.offsetX - sX),
      y: mark.y + (em.offsetY - sY),
      ox: mark.ox + (em.offsetX - sY),
      oy: mark.oy + (em.offsetY - sY),
      color: mark.color,
    };
    list.splice(i, 1, iframe);
    /* 监听键盘, 使矩形在移动后仍可删除, 在点击未移动过的矩形时仍有一次监听 */
    delLine(cav, ctx, list, i);
    // 重新绘制
    reDrawLine(cav, ctx, list, i);
  };

  cav.onmouseup = function () {
    // 重绘矩形
    reDrawLine(cav, ctx, list, i);

    // 初始化
    drawLine(cav, list, i, lineColors, maxCounts);
  };
}
function delLine(cav, ctx, list, i) {
  if (i === null) {
    // i为null时阻止按键监听事件冒泡
    document.onkeydown = function () {
      return false;
    };
  } else {
    // 监听按键事件
    document.onkeydown = function (k) {
      if (k.code === 'Delete' && i !== null) {
        if (list.length >= 1) {
          // 删除数组元素
          list.splice(i, 1);
          // 重绘矩形
          reDrawLine(cav, ctx, list);
        } else {
          /* 矩形数组长度为0, 已将矩形框全部删除 */ ctx.clearRect(
            0,
            0,
            cav.width,
            cav.height
          );
        }
        // 重置监听状态, 防止删除完毕后, 按键监听不消失
        delLine(cav, ctx, list, null);
        // 重绘矩形
        reDrawLine(cav, ctx, list);
        // 初始化
        drawLine(cav, list, undefined, lineColors, maxCounts);
      }
    };
  }
}
function getLineL(x1, y1, x2, y2, x3, y3, delta) {
  /**
   * 判断由x3, y3定义的点是否在由x1, y1 和 x2, y2定义的线段之上？
   * @param x1 - 线段的第一个端点的x坐标
   * @param y1 - 线段的第一个端点的y坐标
   * @param x2 - 线段的第二个端点的x坐标
   * @param y2 - 线段的第二个端点的y坐标
   * @param x3 - 要判断的点的x坐标
   * @param y3 - 要判断的点的y坐标
   * @param delta - 误差范围
   * @return true or false.
   */
  const xa = x2 - x1;
  const ya = y2 - y1;
  const xb = x3 - x1;
  const yb = y3 - y1;
  return (
    Math.abs(yb / xb - ya / xa) <= delta &&
    Math.min(x1, x2) <= x3 &&
    Math.max(x1, x2) >= x3 &&
    Math.min(y1, y2) <= y3 &&
    Math.max(y1, y2) >= y3
  );
}
